<template>
  <section class="site-project">
    <div class="content content-main first-container">
      <div class="txt-container">
        <span v-if="projectData != undefined" class="project-name">{{projectData.title}}</span>
      </div>
    </div>

    <div class="content content-project-intro">
        
      <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <div class="row">
                    <div class="col-lg-3 mb-4 mb-lg-0">
                        <div class="row">

                            <div class="col-lg-12 col-6 mb-3">
                                <span class="item"><strong>Client:</strong>
                                    <div class="text-1">
                                      <span>
                                        {{projectData.client}}
                                      </span>
                                    </div>
                                </span>
                            </div>

                            <div class="col-lg-12 col-6 mb-3">
                                <span class="item"><strong>Category:</strong>
                                    <div class="text-1">
                                      <span>
                                        {{projectData.category}}
                                      </span>
                                    </div>
                                </span>
                            </div>

                            <div class="col-lg-12 col-6 mb-3">
                                <span class="item"><strong>Technology used:</strong>
                                    <div class="text-1">
                                      <span>
                                        {{projectData.tech}}
                                      </span>
                                    </div>
                                </span>
                            </div>

                            <div class="col-lg-12 col-6 mb-3">
                                <span class="item"><strong>Company at the time:</strong>
                                    <div class="text-1">
                                      <span>
                                        {{projectData.company}}
                                      </span>
                                    </div>
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <span class="content">{{projectData.body}}</span>

                        <div v-show="projectData.external_link != null" class="links">
                          <a target="_blank" :href="projectData.external_link">{{projectData.external_link}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  
    <div class="content content-info">
        <div class="container-fluid">
          <span class="title">HIGHLIGHT <span class="small">ハイライト</span></span>
          
          <div class="contents" v-for="item in projectData.showcase" :key="item.id">
            <div v-html="item.content"></div>
          </div>

        </div>
    </div>

    <div class="content-staytune">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center">
            <span class="staytune">Working on content and layout, stay tuned</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content content-gallery">
      <div class="reveal" animate-me>
        <a class="point" v-for="photo in projectData.gallery" :key="photo.id">
          <img style="display: none" :src="photo.src"/>
        </a>
        <div class="bg-reveal"></div>
      </div>
      <!-- to refactor -->
      <div class="gallery-mobile d-sm-none" animate-me>
        <div id="carouselExampleControls" class="carousel slide myCarousel" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="i in projectData.gallery" :key="i.id" :class="i.id === 1 ? 'active' : ''">
              <img class="d-block w-100" :src="i.src">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>

    <!-- text-ticker -->
    <div class="block-text-ticker" text-ticker>
      <div class="gsap-wrapper" data-text-ticker="rtl">
          <ul ticker-rtl="ticker-rtl">
              <li class="ticker-items">
                <span class="text">view more — </span>
                <span class="text text-outline">view more — </span>
              </li>
          </ul>
      </div>
    </div>
    <!-- text-ticker -->

    <div class="content content-other-projects">
      <div class="container-fluid">
        <div class="other-projects">
          <div class="slides" data-slick='{"variableWidth": true, "slidesToShow": 3, "responsive": [{ "breakpoint": 1200, "settings": { "slidesToShow": 1 } }]}'>
            <div class="slide" v-for="i in otherProjects" :key="i.id">
              <div class="block-product">
                <router-link :to="i.path">
                  <img class="img-fluid" :src="i.thumbnail"/>
                  <span class="project-name">{{i.name}}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, reactive, computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Func from '@/helpers/Function';
import Data from '@/helpers/Data';
import { useStore } from 'vuex';

export default {
  setup(props, {emit}) {
		const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userMode = computed(() => store.state.account.mode);
    const projectData = ref({});
    const otherProjects = ref([]);
    const img = ref(null);

    onBeforeMount(()=>{
      projectData.value = Data[route.query.title];
      let newProjArr = Object.keys(Data);
      newProjArr = newProjArr.sort(()=> Math.random() - 0.5);
      
      newProjArr.forEach(title => {
        if (title !== route.query.title){
          otherProjects.value.push(title);
        }
      });

      otherProjects.value = otherProjects.value.slice(0,3);
      otherProjects.value = otherProjects.value.map(p => {
        let o = {};
        o.name = p;
        o.path = Data[p].path;
        o.thumbnail = Data[p].thumbnail;
        return o;
      });
    });
    
    onMounted(() => {
      var calcX, calcY;
      var circleWidth, circleHeight, elementTop, elementLeft;
      var controller;
      Func.resetPage();
      Func.setupScrolltop();
      // Func.toggleLightbox(projectData.value.external_link);
      setupScrollMagic();
      // ticker
      let speed = 100;
      let $ticker = $('[text-ticker]').find('ul');

      let $tickerWidth = $ticker.width();
      let $spanWidth = $ticker.find('.ticker-items').width() + 24;
      $ticker.find('.ticker-items').clone().appendTo($ticker);
      $ticker.find('.ticker-items').wrapAll('<li class="ticker-wrapper">');

      let initDuration = $tickerWidth / speed;
      let loopDuration = $spanWidth / speed;
      const t2 = window.TweenMax;
      t2.set('.ticker-wrapper', {x: $tickerWidth });
      const tl2 = gsap.timeline()
        .to( '.ticker-wrapper', initDuration, { x: 0, ease: 'power0' } )
        .to( '.ticker-wrapper', loopDuration, { x: -$spanWidth, ease: 'power0', repeat: -1 } );
      tl2.progress(0.5);
      // ticker
      $('[animate-me]').each(function () {
        const c = new window.ScrollMagic.Controller();
        const t = gsap.from($(this), 1, { autoAlpha: 0, y: 50 });

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: 0,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
      // stagger
      $('[animate-stagger]').each(function () {
        const c = new window.ScrollMagic.Controller();

        const t = window.TweenMax.staggerFrom($(this).find('[animate-item]'), 0.7, { autoAlpha: 0, y: 50 }, 0.3);

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: -100,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
      //
      $('[data-slick]').slick({
        dots: false,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>'
      });
      //
      if(userMode.value == 'dark') {
        $(".site-project").addClass('darkmode');
      } else {
        $(".site-project").removeClass('darkmode');
      }

      img.value = projectData.value.showcase;
      $('.content-main').css('background-image', "url("+ projectData.value.thumbnail + ")");
      //
      // $(".content-info .inner-container .texts").append(projectData.value.body);
      //
      $(".point").on("mouseover", triggerReveal);
      $(".point").mouseout(function() {
        setTimeout(()=>{
          $(".bg-reveal").css('clip-path', 'circle(0px at '+ calcX +' ' + calcY + ')');
        }, 300)
      });

      function triggerReveal(e) {
        e.preventDefault();
        var bgImg = $(this).find('img').attr('src');
        circleWidth = circleHeight = $(this).innerWidth();
        elementTop = $(this).position().top;
        elementLeft = $(this).position().left;
        //
        calcX = elementLeft + (circleWidth/2);
        calcY = elementTop + (circleHeight/2);
        calcX += 'px';
        calcY += 'px';
        $(".bg-reveal").css('clip-path', 'circle(0px at '+ calcX +' ' + calcY + ')');    
        // console.log(elementTop, elementLeft, calcX, calcY);
        var w = $(window).innerWidth(); //width of background
        var h = $(window).innerHeight(); //height of background
        var d = Math.pow(w, 2) + Math.pow(h, 2);
        var r = Math.sqrt(d); //radius of circle to expand to
        r = r + 'px';
        // console.log(r);
        setTimeout(()=>{
          $('.bg-reveal').css('background', "url(" + bgImg + ")");
          $('.bg-reveal').css('background-color', '#333');
          $('.bg-reveal').css('background-size', 'contain');
          $('.bg-reveal').css('background-repeat', 'no-repeat');
          $('.bg-reveal').css('background-position', 'center');
          $(".bg-reveal").css('clip-path', 'circle(' + r + ' at '+ calcX +' ' + calcY + ')');
        },300)
      }

      function setupScrollMagic() {
        var sections = [".content-info"];
        controller = new ScrollMagic.Controller();

        sections.forEach(function (section, index) {     
          var tm = new TimelineMax();
          var scene = new ScrollMagic.Scene({ 
            triggerElement: section, 
            reverse: false
          })
          .setTween(tm)
          .addTo(controller);
          scene.on("start", function (event) {
            $(section).find('.title').addClass('active');
          });
        });
      }
    });

    onBeforeUnmount(()=>{
      $(".lightbox").removeClass('show');
      $(".lightbox").addClass('hide');
      $("body").removeClass("noscroll");
    })

    return {
      projectData,
      img,
      otherProjects
    }
  }
}
</script>

<style scoped>
</style>
